import { FC, useEffect } from "react"
import { useBoolean } from "usehooks-ts"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoginService, {
  LoginError,
} from "@hornet-web-react/core/services/LoginService"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import useLoginAction from "@hornet-web-react/login/hooks/use-login-action"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import { GoogleLogin } from "@react-oauth/google"
import styled from "styled-components"
import LoginAnalyticsEvent, {
  UserSignedUpOrigin,
  UserSignedUpProvider,
} from "@hornet-web-react/login/models/login-analytics-event"
import { useHornetSession } from "../../hooks/use-hornet-session"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { useHornetLoginSuccess } from "../../hooks/use-hornet-login-success"

type LoginViaGoogleButtonProps = {
  isDisabled: boolean
  redirectTo: string | null
}

const Wrapper = styled.div`
  width: 40px;
  height: 44px;
`

const LoginViaGoogleButton: FC<LoginViaGoogleButtonProps> = ({
  isDisabled,
  redirectTo,
}) => {
  const loginService = useCoreService<LoginService>(CORE_TYPES.LoginService)
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)
  const { reportEvent } = useEventTrackerService()
  const { mutate: mutateSession } = useHornetSession()
  const { handleLoginOnSuccess } = useHornetLoginSuccess(false, redirectTo)
  const { submitError, clearSubmitError, onLoginError, onLoginSuccess } =
    useLoginAction(mutateSession, handleLoginOnSuccess)

  const { value: isSubmitting, setValue: setIsSubmitting } = useBoolean(false)
  const { value: isButtonDisabled, setTrue: disableButton } =
    useBoolean(isDisabled)

  // error handling
  useEffect(() => {
    if (submitError) {
      alert(submitError.title + "\n" + submitError.message)
      clearSubmitError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitError])

  const finishLogin = async (authToken: string) => {
    setIsSubmitting(true)
    clearSubmitError()

    const provider = UserSignedUpProvider.Facebook
    const origin = UserSignedUpOrigin.Homepage

    try {
      const loginResult = await loginService.loginViaGoogle(authToken)

      if (isRight(loginResult)) {
        await onLoginSuccess(
          unwrapEither(loginResult),
          UserSignedUpProvider.Facebook,
          UserSignedUpOrigin.Homepage
        )
        return
      }

      const { error, errorTitle, errorMessage } = unwrapEither(loginResult)
      onLoginError(error, provider, origin, errorTitle, errorMessage)
    } catch (error) {
      // unknown error
      onLoginError(LoginError.ServerError, provider, origin)

      if (error instanceof Error) {
        loggerService.logExceptionWithSentry(
          error,
          loggerService.createLoggingContext({
            component: "LoginViaGoogleButton",
            step: "handleLogin",
          })
        )
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClick = async (response: { credential: string }) => {
    try {
      void reportEvent(
        LoginAnalyticsEvent.welcomeTapOnLogin(
          UserSignedUpProvider.Google,
          UserSignedUpOrigin.Homepage
        )
      )

      void finishLogin(response.credential)
    } catch (error) {
      disableButton()
    }
  }

  return (
    <Wrapper>
      <GoogleLogin
        size={"large"}
        type={"icon"}
        shape={"circle"}
        onSuccess={handleClick}
      />
    </Wrapper>
  )
}

export default LoginViaGoogleButton
