import { SuccessfulLoginPayload } from "@hornet-web-react/core/services/LoginService"
import { useNavigation } from "./use-navigation"
import { useRouter } from "next/router"
import { useCallback } from "react"

export const useHornetLoginSuccess = (
  isSignup: boolean,
  redirectTo: string | null
) => {
  const { routeForHome, routeForOnboarding } = useNavigation()
  const router = useRouter()

  const handleLoginOnSuccess = useCallback(
    (userPayload: SuccessfulLoginPayload) => {
      if (
        typeof routeForOnboarding !== "undefined" &&
        (userPayload.currentUser.isNew || isSignup)
      ) {
        void router.push(routeForOnboarding)
        return
      }

      if (redirectTo) {
        void router.push(redirectTo)
        return
      }

      void router.push(routeForHome)
    },
    [isSignup, redirectTo, routeForHome, routeForOnboarding, router]
  )

  return { handleLoginOnSuccess }
}
