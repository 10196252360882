import { useCallback, useState } from "react"
import {
  LoginError,
  SuccessfulLoginPayload,
} from "@hornet-web-react/core/services/LoginService"
import invariant from "tiny-invariant"
import { isStrictNever } from "@hornet-web-react/core/utils"
import useTranslation from "next-translate/useTranslation"
import { LoggedInUser } from "@hornet-web-react/core/types/session"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import LoginAnalyticsEvent, {
  UserSignedUpOrigin,
  UserSignedUpProvider,
} from "../models/login-analytics-event"

type SubmitError = {
  title: string | null
  message: string
} | null

const i18nKey = "login:hooks.use_login_action"

export default function useLoginAction(
  mutateSession: () => Promise<unknown>,
  onSuccess: (userPayload: SuccessfulLoginPayload) => void
) {
  const { t } = useTranslation()
  const {
    user: { storeUser },
  } = useSessionApi()

  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)

  const [submitError, setSubmitError] = useState<SubmitError>(null)
  const [isShowingCaptcha, setIsShowingCaptcha] = useState(false)
  const hideCaptcha = useCallback(() => setIsShowingCaptcha(false), [])
  const clearSubmitError = useCallback(() => setSubmitError(null), [])

  const onLoginError = useCallback(
    (
      error: LoginError,
      provider: UserSignedUpProvider,
      origin: UserSignedUpOrigin,
      errorTitle?: string,
      errorMessage?: string
    ) => {
      void eventTrackerService.report(
        LoginAnalyticsEvent.userLoginFailed(provider, origin)
      )

      switch (error) {
        case LoginError.ServerError:
          setSubmitError({
            title: null,
            message: t(`${i18nKey}.errors.server_error`),
          })
          break

        case LoginError.AccountSuspended:
          setSubmitError({
            title: null,
            message: t(`${i18nKey}.errors.account_suspended`),
          })
          break

        case LoginError.AccountNotFound:
        case LoginError.InvalidCredentials:
          setSubmitError({
            title: null,
            message: t(`${i18nKey}.errors.invalid_credentials`),
          })
          break

        case LoginError.TeapotMessageError:
          invariant(typeof errorTitle === "string")
          invariant(typeof errorMessage === "string")

          setSubmitError({
            title: errorTitle,
            message: errorMessage,
          })
          break

        case LoginError.RequireCaptcha:
          setIsShowingCaptcha(true)
          break

        default:
          isStrictNever(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onLoginSuccess = useCallback(
    async (
      userPayload: SuccessfulLoginPayload,
      provider: UserSignedUpProvider,
      origin: UserSignedUpOrigin
    ) => {
      void eventTrackerService.report(
        LoginAnalyticsEvent.userLoggedIn(provider, origin)
      )

      try {
        const user = LoggedInUser.parse(userPayload)
        storeUser(user)

        // without this delay, the session is not mutated at all
        await new Promise((r) => setTimeout(r, 100))
        await mutateSession()

        onSuccess(userPayload)
      } catch (error) {
        onLoginError(LoginError.ServerError, provider, origin)

        if (error instanceof Error) {
          loggerService.logExceptionWithSentry(
            error,
            loggerService.createLoggingContext({
              hook: "useLoginAction",
              provider: provider,
            })
          )
        }
      }
    },
    [
      eventTrackerService,
      loggerService,
      mutateSession,
      onLoginError,
      storeUser,
      onSuccess,
    ]
  )

  return {
    submitError,
    clearSubmitError,
    setSubmitError,
    isShowingCaptcha,
    hideCaptcha,
    onLoginSuccess,
    onLoginError,
  }
}
