import { useState } from "react"
import { useRouter } from "next/router"
import {
  useSessionApi,
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { debug } from "@hornet-web-react/core/utils"
import GoogleAnalyticsService from "@hornet-web-react/core/services/GoogleAnalyticsService"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { SuccessfulLoginPayload } from "@hornet-web-react/core/services/LoginService"
import { LoggedInUser } from "@hornet-web-react/core/types/session"
import { useEffectOnce } from "usehooks-ts"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"

export const useSessionUserLogin = (mutateSession: () => Promise<unknown>) => {
  const [hasAuthError, setHasAuthError] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const { appUrl } = useSessionEnvironment()
  const { locale } = useSessionDevice()
  const { isAuthenticated, currentUser } = useSessionUser()
  const {
    user: { storeUser, logout },
  } = useSessionApi()

  const apiService = useCoreService<ApiService>(CORE_TYPES.ApiService)
  const googleAnalyticsService = useCoreService<GoogleAnalyticsService>(
    CORE_TYPES.GoogleAnalyticsService
  )

  // this effect is unnecessary *when SSG without OTP* if the session is set
  // via cookie in `useAppStart` - but this effect doesn't "wait" for it,
  // so in this (edge) case there is this unnecessary call and re-render

  useEffectOnce(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const otp = searchParams.get("otp")

    if (!otp && isAuthenticated && currentUser?.profileId) {
      googleAnalyticsService.setDimensions({
        USER_PROFILE_ID: currentUser.profileId,
      })

      setHasAuthError(false)
      return
    }

    setIsLoading(true)

    searchParams.delete("otp")
    searchParams.set("locale", locale)

    const { abortController, apiRequest } =
      apiService.useAbortableEndpoint<SuccessfulLoginPayload>(
        ApiService.getEndpoint(ApiServiceEndpoint.ApiLoginPost, [
          appUrl,
          searchParams.toString(),
        ]),
        { otp }
      )

    apiRequest()
      .then((successPayload: SuccessfulLoginPayload): void => {
        setIsLoading(false)
        setHasAuthError(false)

        storeUser(LoggedInUser.parse(successPayload))

        void mutateSession()

        googleAnalyticsService.setDimensions({
          USER_PROFILE_ID: successPayload.currentUser?.profileId,
        })

        // clean up the OTP from the URL
        // WARN: this most likely does not work in webview
        if (otp) {
          searchParams.delete("otp")

          router.push(
            `${window.location.pathname}?${searchParams.toString()}`,
            undefined,
            {
              shallow: true,
            }
          )
        }
      })
      .catch(() => {
        setIsLoading(false)

        // everything works as expected in this case
        // no need for other error tracking etc
        if (abortController.signal.aborted) {
          return
        }

        // no dangling previous cookies etc
        logout()

        setHasAuthError(true)
      })

    return () => {
      abortController.abort()
    }
  })

  debug(`useSessionUserLogin: hasAuthError: ${hasAuthError ? "yes" : "no"}`)

  return { hasAuthError, isLoading, isLoggedIn: hasAuthError === false }
}
