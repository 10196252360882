import { FC } from "react"
import styled from "styled-components"
import useTranslation from "next-translate/useTranslation"

type OrTextProps = {
  text?: string
}

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
  font-weight: 400;
  margin: ${({ theme }) => theme.spacing.regular} 0;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Text = styled.span`
  display: block;
  padding: 0 ${({ theme }) => theme.spacing.less};
`

const Line = styled.span`
  width: 40px;
  border-bottom: 1px solid #ccc;
`

const OrText: FC<OrTextProps> = ({ text }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Line />
      <Text> {text || t("hornet:components.layout.or_text.text")} </Text>
      <Line />
    </Wrapper>
  )
}

export default OrText
