import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { useBoolean, useEffectOnce } from "usehooks-ts"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import appConfig from "../../app-config"
import { LoginResponse } from "@greatsumini/react-facebook-login"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoginService, {
  LoginError,
} from "@hornet-web-react/core/services/LoginService"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import useLoginAction from "@hornet-web-react/login/hooks/use-login-action"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import Spinner from "@hornet-web-react/core/components/UI/Spinner"
import { CgFacebook } from "react-icons/cg"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { FacebookLoginClient } from "@greatsumini/react-facebook-login"
import invariant from "tiny-invariant"
import LoginAnalyticsEvent, {
  UserSignedUpOrigin,
  UserSignedUpProvider,
} from "@hornet-web-react/login/models/login-analytics-event"
import { useHornetSession } from "../../hooks/use-hornet-session"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { useHornetLoginSuccess } from "../../hooks/use-hornet-login-success"

type LoginViaFacebookButtonProps = {
  redirectTo: string | null
}

declare global {
  interface Window {
    FB
  }
}

const FacebookButton = styled(IconButton)`
  background: #1877f2;
  color: #fff;
  border: 2px solid #1877f2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 40px;

  &:hover,
  &:focus {
    color: #fff;
    border-color: #1877f2;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.color.tint.disabled};
    border-color: ${({ theme }) => theme.color.tint.disabled};
  }
`

// const i18nKey = "hornet:components.login.login_via_facebook_button"

const LoginViaFacebookButton: FC<LoginViaFacebookButtonProps> = ({
  redirectTo,
}) => {
  const { locale } = useSessionDevice()
  const loginService = useCoreService<LoginService>(CORE_TYPES.LoginService)
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)
  const { reportEvent } = useEventTrackerService()
  const { mutate: mutateSession } = useHornetSession()
  const { handleLoginOnSuccess } = useHornetLoginSuccess(false, redirectTo)

  const { submitError, clearSubmitError, onLoginError, onLoginSuccess } =
    useLoginAction(mutateSession, handleLoginOnSuccess)

  const { value: isSubmitting, setValue: setIsSubmitting } = useBoolean(false)
  const {
    value: isDisabled,
    setTrue: disableButton,
    setFalse: enableButton,
  } = useBoolean(true)
  const [loginStatusResponse, setLoginStatusResponse] =
    useState<LoginResponse | null>(null)

  // error handling
  useEffect(() => {
    if (submitError) {
      alert(submitError.title + "\n" + submitError.message)
      clearSubmitError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitError])

  useEffectOnce(() => {
    const initFb = async () => {
      // HACK: remove once this is merged and pkg updated
      // https://github.com/greatSumini/react-facebook-login/pull/485
      if (typeof window.FB === "undefined") {
        await FacebookLoginClient.loadSdk(locale, false)
      }

      FacebookLoginClient.init({
        appId: appConfig.auth.facebook.appId,
        version: appConfig.auth.facebook.version,
      })

      FacebookLoginClient.getLoginStatus(function (response) {
        setLoginStatusResponse(response)
        enableButton()
      }, true)
    }

    void initFb()
  })

  const finishLogin = async (fbAuthToken: string) => {
    setIsSubmitting(true)
    clearSubmitError()

    const provider = UserSignedUpProvider.Facebook
    const origin = UserSignedUpOrigin.Homepage

    try {
      const loginResult = await loginService.loginViaFacebook(fbAuthToken)

      if (isRight(loginResult)) {
        await onLoginSuccess(
          unwrapEither(loginResult),
          UserSignedUpProvider.Facebook,
          UserSignedUpOrigin.Homepage
        )
        return
      }

      const { error, errorTitle, errorMessage } = unwrapEither(loginResult)
      onLoginError(error, provider, origin, errorTitle, errorMessage)
    } catch (error) {
      // unknown error
      onLoginError(LoginError.ServerError, provider, origin)

      if (error instanceof Error) {
        loggerService.logExceptionWithSentry(
          error,
          loggerService.createLoggingContext({
            component: "LoginViaFacebookButton",
            step: "handleLogin",
          })
        )
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleLogin = (response: LoginResponse) => {
    if (
      response.status !== "connected" ||
      !response.authResponse?.accessToken
    ) {
      return
    }

    void finishLogin(response.authResponse.accessToken)
  }

  const handleClick = async () => {
    try {
      invariant(loginStatusResponse, "loginStatusResponse is null")

      void reportEvent(
        LoginAnalyticsEvent.welcomeTapOnLogin(
          UserSignedUpProvider.Facebook,
          UserSignedUpOrigin.Homepage
        )
      )

      if (loginStatusResponse.status === "connected") {
        handleLogin(loginStatusResponse)
        return
      }

      if (loginStatusResponse.status === "not_authorized") {
        // The person is logged into Facebook, but not your app.
        FacebookLoginClient.login(handleLogin, {
          scope: appConfig.auth.facebook.scope,
          return_scopes: false,
          auth_type: "reauthorize",
        })
        return
      }

      // some other situation
      FacebookLoginClient.login(handleLogin, {
        scope: appConfig.auth.facebook.scope,
        return_scopes: false,
        auth_type: "reauthenticate",
      })
    } catch (error) {
      disableButton()
    }
  }

  return (
    <FacebookButton
      onClick={handleClick}
      disabled={isDisabled || isSubmitting}
      type={"button"}
    >
      {isSubmitting ? (
        <Spinner size={"auto"} />
      ) : (
        <CgFacebook style={{ color: "#fff" }} size={24} />
      )}
    </FacebookButton>
  )
}

export default LoginViaFacebookButton
